import React, { Component } from "react";
import data from "../data/stories";
import Card from "../components/card";

const Story = ({ image, title, description }) => {
  return (
    <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12 column">
      <div className="story">
        <Card image={image} title={title} description={description} className={"event-card"}/>
      </div>
    </div>
  );
};

export default class extends Component {
  constructor (props) {
    super(props);
  }

  componentDidMount () {
  }

  render () {
    let slides = [];
    for (let i = 0; i < data.length; i = i + 2) {
      slides.push([data[i], data[i + 1]]);
    }
    return (
      <section id="stories" className="section events">

        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="section-heading">
                <h6 className="subtitle text-primary">SUCCESS STORIES</h6>
                <h2 className="display-4">Stories that Inspire Us</h2>
              </div>
            </div>
          </div>
          <div id="storyControls" className="carousel slide" data-ride="carousel">
            <div className="carousel-inner">
              {
                slides.map((slide, index) => {
                  return (
                    <div className={"carousel-item " + (index === 0 ? "active" : "")} key={index}>
                      <div className="row">
                        {slide.map((event, index2) => {
                          return event ? <Story {...event} key={index2}/> :
                            <div className={"col-lg-3 col-md-4 col-sm-6 col-xs-6 column"} {...event}
                                 key={index2}/>;
                        })}
                      </div>
                    </div>
                  );
                })
              }
            </div>
            <a className="carousel-control-prev" href="#storyControls" role="button" data-slide="prev">
              <span className="carousel-control-prev-icon" aria-hidden="true"></span>
              <span className="sr-only">Previous</span>
            </a>
            <a className="carousel-control-next" href="#storyControls" role="button" data-slide="next">
              <span className="carousel-control-next-icon" aria-hidden="true"></span>
              <span className="sr-only">Next</span>
            </a>
          </div>

        </div>


      </section>
    );
  }
}

//export default OtherMentors;
