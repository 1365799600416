import MOBYCY from "../images/ecellpb/MobycyLaunch.jpg";
import StartupBaddi from "../images/ecellpb/StartupBaddi.jpg";
import EAD from "../images/ecellpb/EAD_s.jpg";
import Golf3 from "../images/ecellpb/golf3.jpg";
import Ideate from "../images/ecellpb/Ideate.jpg";
import GuruTakk from "../images/ecellpb/GuruTalkSameerGupta.jpg";
import druv from "../images/ecellpb/dhruvSehra.jpg";
import GuruTalk from "../images/ecellpb/GuruTalk.jpg";

const data = [
  {
    "image": MOBYCY,
    "title": "MOBYCY Launch"
  },
  {
    "image": StartupBaddi,
    "title": "Joint Meeting E-CELL Hp and Pb Campus"
  },
  {
    "image": EAD,
    "title": "Entrepreneurship Awareness Drive"
  },
  {
    "image": Golf3,
    "title": "Golf and Connect"
  },
  {
    "image": Ideate,
    "title": "GURU TALK by Mr. Ajay Suman Shukla"
  },
  {
    "image": GuruTakk,
    "title": "Guru Talk by Abhijeet Bhattacharya"
  },
  {
    "image": druv,
    "title": "Panel Discussion"
  },
  {
    "image": GuruTalk,
    "title": "Guru Talk by Rahul Narvekar"
  }
];

export default data;
