import sunil from "../images/ecellpb/mentor-sunil.jpg";
import vineet from "../images/ecellpb/mentor-vineet.png";
import jagrit from "../images/ecellpb/mentor-jagrit-gupta.jpg";
import vivek from "../images/ecellpb/mentor-vivek-pratap.jpg";
import brijesh from "../images/ecellpb/mentor-brijesh.jpg";
import anurag from "../images/ecellpb/mentor-anurag-maloo.png";
import adarsh from "../images/ecellpb/mentor-adarsh.png";
import sumeer from "../images/ecellpb/mentor-sumeer-walia_s.jpg";
import ashutosh from "../images/ecellpb/mentor-ashutosh-burnwal.jpg";
import sidharth from "../images/ecellpb/mentor-sidharth-mittal.jpg";
import sivesh from "../images/ecellpb/mentor-sivesh-kumar.jpg";
import yuvraj from "../images/ecellpb/mentor-yuvraj-maheshwari.jpg";

const data = [
  {
    "image": sunil,
    "name": "Sunil Girdhar ",
    "title": "Co-Founder - Nation Cultus Consulting"
  },
  {
    "image": vineet,
    "name": "Vineet Khurana",
    "title": "Vice president - CAN"
  },
  {
    "image": jagrit,
    "name": "Jagrit Gupta",
    "title": "Co- Founder - Subhe"
  }, {
    "image": vivek,
    "name": "Vivek Pratap Singh Rathore",
    "title": "Co- Founder - Subhe"
  },
  {
    "image": brijesh,
    "name": "Brijesh Aggarwal",
    "title": "Founder - DocsinBlocks"
  }, {
    "image": anurag,
    "name": "Anurag Maloo",
    "title": "Regional Manager- Techstars"
  }, {
    "image": sumeer,
    "name": "Sumeer Walia",
    "title": "Director - Ceed"
  }, {
    "image": adarsh,
    "name": "Dr. Adarsh Aggarwal",
    "title": "Senior Mentor CEED"
  }, {
    "image": sivesh,
    "name": "Sivesh Kumar",
    "title": "Startup Guy | Angel Investments | TEDx Speaker "
  }, {
    "image": ashutosh,
    "name": "Ashutosh Kumar Burnwal",
    "title": "Co-founder, Buddy4Study.com"
  }, {
    "image": yuvraj,
    "name": "Yuvraj Maheshwari",
    "title": "Founder at Yuvraj Financial Advisory"
  }, {
    "image": sidharth,
    "name": "Sidharth Mittal",
    "title": "Founder at  ExpertConnect India"
  }
];

export default data;
