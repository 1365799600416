import React from "react";

import data from "../data/mentors-with-picture";

const Mentors = () => (
  <section id="mentors" className="section main team fill-bg">

    <div className="container">
      <div className="row">
        <div className="col-lg-12">
          <div className="section-heading">
            <h6 className="subtitle text-primary">E-CELL MENTORS</h6>
            <h2 className="display-4">Meet The Experts</h2>
          </div>
        </div>
      </div>

      <div className="row justify-content-center">
        {
          data.map((mentor, index) => {
            return (
              <div className="column fix-col" key={index}>
                <div className="team-block">
                  <div className="image">
                    <span className="imageUrl" style={{ backgroundImage: "url(" + mentor.image + ")" }}/>
                  </div>
                  <div className="team-content">
                    <h4>{mentor.name}</h4>
                    <p className="occupation">{mentor.title}</p>
                  </div>
                </div>
              </div>

            );
          })
        }
      </div>
    </div>
  </section>
);

export default Mentors;
