import startUpWeekend from "../images/ecellpb/Startupweekend-new.jpg";
import surge from "../images/ecellpb/surge.jpg";
import fireChat from "../images/ecellpb/firechat.jpg";
import huddle from "../images/ecellpb/StartupHuddlech.jpg";
import ewl from "../images/ecellpb/EarnWHileYouLEarn.jpg";
import mentor from "../images/ecellpb/mentor.jpg";
import eMela from "../images/ecellpb/E-Mela.jpg";
import startUpDay from "../images/ecellpb/Startupday.jpg";
import cd from "../images/ecellpb/connectTheDots223.jpg";

const data = [
  {
    "image": startUpWeekend,
    "title": "START-UP WEEKEND",
    "description": "Startup Weekend Chandigarh, a 3-day overnight event organized by E-CELL from 27th-29th September 2018. “Startup Weekends are weekend-long, practical involvement where entrepreneurs and aspirant entrepreneurs have an opportunity to realize if the startup thoughts really are feasible. Nearly half of Startup Weekend’s participants possess technical backgrounds, the other half encompass business backgroundsStartup Powered by Google”."
  },
  {
    "image": surge,
    "title": "SURGE 0.1 ",
    "description": "A five-day gala celebration of entrepreneurship” Chitkara University, Himachal Pradesh hosted SURGE0.1 on 19th and 20th February 2019. The magnificent event saw a participation of over 700 entrepreneurs, budding entrepreneurs, researchers, faculty, staff & students. "
  }, {
    "image": fireChat,
    "title": "Fireside Chat",
    "description": "Fireside Chat, one of the highlights of Surge 0.1 which showed Shorabh Sitaram, CEO Keventers go completely candid with our interviewer Namita Satija."
  },
  {
    "image": huddle,
    "title": "START-UP HUDDLE",
    "description": "Startup Huddle is the initiative of Global Entrepreneurship Network, CEED, Chitkara University launched it successfully in India. "
  },
  {
    "image": ewl,
    "title": "Earn While You Learn",
    "description": "Earn While You Learn: A program focused on giving our budding entrepreneurs a sense of working in a Start-up.  It is a program where already existing startups provide real time internships which helps them gain market experience and other values that a entrepreneur needs to learn."
  },
  {
    "image": mentor,
    "title": "MENTOR-MENTEE CONNECT SERIES",
    "description": "“Mentor/Mentee Meet” offers mentoring for the budding entrepreneurs, to set their career and development goal. The aim of this meet to give opportunity to budding entrepreneurs to find solutions by interacting with esteemed industry experts."
  },
  {
    "image": eMela,
    "title": "E-MELA",
    "description": "E-Mela was an entrepreneurship theme-based fun event where students put up the stalls of several eateries, fun games, art, and activities. This results in students learning more about sales, social interaction and leadership skills."
  },
  {
    "image": startUpDay,
    "title": "STARTUP DAY",
    "description": "Startup Day, Chitkara University’s annual business plan competition. To test student’s business acumen, hone management skills to produce the leading firms of tomorrow. Pitching in front of an esteemed panel of leading experts encompassing leading angel investors, venture capitalists, and industrialists from reputed firms. Startup Day provides a perfect opportunity to network with the best and explore the entrepreneur within."
  }, {
    "image": cd,
    "title": "Connect The Dots",
    "description": "E-Cell Chitkara University organized the first-ever round table meet of E-cells of Pan India where president and managers share their beliefs and experience on building Entrepreneurship cell and ideas for betterment of E-Cells. "
  }
];

export default data;
