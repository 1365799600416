import ankit from "../images/ecellpb/team-ankit.png";
import karan from "../images/ecellpb/team-karan.png";
import abhisar from "../images/ecellpb/team-abhisar.png";
import vipul from "../images/ecellpb/team-vipul.png";
import Aditya from "../images/ecellpb/team-Aditya.jpg";
import Ashima from "../images/ecellpb/team-Ashima.jpg";
import Manik from "../images/ecellpb/team-Manik.jpg";
import Shobhit from "../images/ecellpb/team-Shobhit.jpg";
import Ujjwal from "../images/ecellpb/team-Ujjwal.jpg";
import Vishal from "../images/ecellpb/team-Vishal.jpg";

const data = [
  {
    "image": ankit,
    "name": "Ankit Mathuria",
    "title": "President, E-Cell"
  },
  {
    "image": vipul,
    "name": "Vipul Sharma",
    "title": "Vice-President, E-Cell"
  },
  {
    "image": karan,
    "name": "Karanveer Thakur",
    "title": "Deputy Vice- President, E-Cell"
  },
  {
    "image": abhisar,
    "name": "Abhisar Ahuja",
    "title": "Deputy Vice President, E-Cell"
  },
  {
    "image": Ashima,
    "name": "Ashima Kamra",
    "title": "Deputy Vice President, E-Cell"
  },
  {
    "image": Aditya,
    "name": "Aditya Bansal",
    "title": "Coordinator, E-Cell"
  },
  {
    "image": Manik,
    "name": "Manik Rohilla",
    "title": "Coordinator, E-Cell"
  },
  {
    "image": Shobhit,
    "name": "Shobhit Singh",
    "title": "Coordinator, E-Cell"
  },
  {
    "image": Ujjwal,
    "name": "Ujjwal Baluja",
    "title": "Coordinator, E-Cell"
  },
  {
    "image": Vishal,
    "name": "Vishal Dua",
    "title": "Coordinator, E-Cell"
  }

];

export default data;
