import React from "react";
import cap from "../images/ceed/cap.png";
import { Link } from "gatsby";

const About = () => (
  <section id="about" className="section about-us">
    <div className="container">
      <div className="row justify-content-end align-items-center ">
        <div className="col-lg-12 col-md-12">
          <div className="video-content">
            <h6 className="text-primary text-uppercase mb-3 subtitle">About E-Cell</h6>
            <h2 className="mb-4 display-4">A CHITKARA UNIVERSITY PUNJAB INITIATIVE TO BUILD START-UP ECOSYSTEM</h2>
            <p className="mb-5">
              At E-Cell, we aim to provide both graduate and under-graduate a preferred ecosystem to discover the
              entrepreneur within. We provide platform for individual to develop leadership skills, art of managing
              events, social development and interaction. Also, efficient interaction between students, faculty and
              working professional with existing Entrepreneurs, professional speakers, venture capitalists and several
              other industrialists.
            </p>
          </div>
        </div>
        <div className="col-lg-12 col-md-12">
          <div className="event-img">
            <span className="image"/>
          </div>
          <div className="action">
            <Link to="/about" className="btn btn-hero btn-rounded">Read More</Link>
          </div>
        </div>
      </div>
    </div>
  </section>
);

export default About;
