import React from "react";

const Banner = () => (
  <section id="banner" className="banner">
    <div className="container">
      <div className="row justify-content-center">
        <div className="col-lg-10 text-center">
          <div className="banner-content">
            <h1 className="lg-title mt-4 mb-5">
              WE BUILD <br/> <span className="highlight">ENTREPRENEURS</span>
            </h1> <a href="https://cuceed.org/register-your-student-startup-at-explore-hub/" className="btn btn-hero btn-rounded" target="_blank">Join
              E-Cell</a>

          </div>
        </div>
      </div>
    </div>
    <div className="scroll">
      <a><span></span></a>
    </div>
  </section>
);

export default Banner;
