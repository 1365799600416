import SchoolPad from "../images/ecellpb/story-abhiraj.jpg";
import Trideal from "../images/ecellpb/story-trideal-back.jpg";
import Itmatic from "../images/ecellpb/story-Itmaitc.jpg";
import Anukai from "../images/ecell/chaiNagari.jpg";

const data = [
  {
    "image": SchoolPad,
    "title": "SchoolPad",
    "description": "Abhiraj Malhotra, Founder of SchoolPad, a 6-year-old startup building SaaS ERPs for K-12 educational institutions. Over a decade of experience in coding web apps in several computer languages. He worked in Infosys for year-and-a-half. He has led a marketing team by bringing experienced people and grow his idea. He believes that time and patience are the keys to sustain in the Indian education market and is looking to grow slowly but steadily. Schoolpad as a startup works on solving school’s problem by making efficient interaction between teachers and parents. Help in progress of school better by making teachers more productive on their day-to-day administrative tasks."
  },
  {
    "image": Trideal,
    "title": "Trideal",
    "description": "Sameer Sharma a business leader and technology enthusiast with 11+ years of experience in technology, marketing automation and hyperlocal space. He started as an entrepreneur under Chitkara Incubation center. He Co-founded Tri Network Limited in 2012 with a vision to empower local business reaching out to targeting customers saving money for the end users. The company got major recognition when in October, 2015 it got acquired by a PAN India Company. It got funded by the investors of Paytm, JustDial and Flipkart. He later founded Unengage Services Pvt Ltd (SHOUTLO) in year 2018."
  },
  {
    "image": Itmatic,
    "title": "Itmatic",
    "description": "Salman Ansari Co-founder and CEO at itmatic. Entrepreneurial leader, general manager and technologist with 5+ years of experience in building a business that has a great impact on society. He is highly proficient International Technical Consultant having extensive experience of working with businesses across the US, UK, Australia, Canada, Dubai, Europe and India. The company ITmatic is a specialized IT & Software Development Company in Panchkula, India that offers innovative & customized website and mobile application solutions. They offer a long term expertise in delivering cost-effective and reliable custom solutions tailored to different specific needs and requirements. "
  },
  {
    "image": Anukai,
    "title": "Anukai",
    "description": "Anukai consists of multi-domain and research oriented people who are goal driven, which enables the startup to march towards its mission with great dedication. Being researchers by experience we try to grow our startup by not only developing new solutions but also optimising the existing ones using latest technologies and state of the art algorithms. Initially founded with 3 members, we are now a 10 member team with each member working on different technologies to give its initiative in development of product. Our startup has its technology roots in the fields of Artificial Intelligence (Computer Vision and Machine learning), Internet of Things and Micro controllers. An amalgamation of all these holds the key to the future because it provides a platform to build not only an efficient solution but also a cost effective one too."
  }
];

export default data;
