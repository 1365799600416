import React from "react";
import Banner from "../components/banner";
import About from "../components/about";
import Mission from "../components/mission";
import Stories from "../components/stories";
import Initiatives from "../components/initiatives";
import Events from "../components/events";
import Mentors from "../components/mentors";
import Team from "../components/team";
import Poster from "../components/poster";
import Gallery from "../components/gallery";
import Layout from "../components/layout";

const IndexPage = () => {
  return (
    <Layout isHomePage={true}>
      <Banner/>
      <About/>
      <Mission/>
      <Stories/>
      <Initiatives/>
      <Events/>
      <Poster/>
      <Team/>
      <Mentors/>
      <Gallery/>
    </Layout>
  );
};
export default IndexPage;
