import React, { Component } from "react";

class Card extends Component {

  constructor (props) {
    super(props);

  }

  componentDidMount () {

  }

  componentWillUnmount () {
  }

  render () {
    const { image, title, description, className } = this.props;
    return (
      <div className={"card " + ((className) ? className : "")}>
        <div className="image" style={{ backgroundImage: "url(" + image + ")" }}/>
        <div className="title">
          <p>{title}</p>
        </div>
        <div className="description">
          <p>{description}</p>
        </div>
      </div>
    );
  }
}

export default Card;
