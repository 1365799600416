import React from "react";
import data from "../data/team";

const Team = () => (
  <section id="team" className="section core team">

    <div className="container">
      <div className="row">
        <div className="col-lg-12">
          <div className="section-heading">
            <h6 className="subtitle text-primary">MEET THE CORE TEAM</h6>
            {/*<h2 className="display-4">Meet The Core Team</h2>*/}
          </div>
        </div>
      </div>

      <div className="row justify-content-center">
        {
          data.map((team, index) => {
            return (
              <div className="column fix-col" key={index}>
                <div className="team-block">
                  <div className="image">
                    <span className="imageUrl" style={{ backgroundImage: "url(" + team.image + ")" }}/>
                  </div>
                  <div className="team-content">
                    <h4>{team.name}</h4>
                    <p className="occupation">{team.title}</p>
                  </div>
                </div>
              </div>
            );
          })
        }
      </div>
    </div>
  </section>
);

export default Team;
