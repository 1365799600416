import React from "react";

const Partners = () => (
  <section id="poster" className="section poster fill-bg">
    <div className="container">
      <div className="row justify-content-center mt-5">
        <div className="row">
          <div className="col-lg-12">
            <div className="section-heading">
              <h6 className="subtitle text-primary">E-CELL TEAM</h6>
              {/*<h2 className="display-4">Our Team</h2>*/}
            </div>
          </div>
        </div>
        <div className="col-lg-12">
          <span className="image"/>
        </div>
      </div>
    </div>
  </section>
);

export default Partners;
