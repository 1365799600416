import React from "react";

const Mission = () => (
  <section id="mission" className="section mission">
    <div className="container">
      <div className="row">
        <div className="col-lg-12">
          <div className="section-heading">
            <h6 className="subtitle text-primary">OUR MISSION</h6>
            <h2 className="display-4">
              Building strong fundamentals of entrepreneurship
            </h2>
          </div>
        </div>
      </div>

      <div className="feature-list row">
        <div className="col-lg-4 col-md-6">
          <div className="feature-box mb-md-3">
            <div className="mb-3">
              <i className="ion-arrow-graph-up-right"></i>
              <h4 className="">Building Entrepreneurship Ecosystem</h4>
            </div>
            <p>Building social and economic environment affecting local and regional entrepreneurship. Serve as
              incubator for creativity, innovation and ideas.</p>
          </div>
        </div>

        <div className="col-lg-4 col-md-6">
          <div className="feature-box mb-md-3">
            <div className="mb-3">
              <i className="ion-ios-lightbulb"></i>
              <h4 className="">Facilitate Entrepreneurial Mindset</h4>
            </div>
            <p>E-CELL aims at promoting the startup culture mindset in young generation and encouraging the new ideas
              and foster the spirit among students to change their idea into business. </p>
          </div>
        </div>

        <div className="col-lg-4 col-md-6">
          <div className="feature-box mb-md-3">
            <div className="mb-3">
              <i className="ion-ios-people"></i>
              <h4 className="">Networking</h4>
            </div>
            <p>E-Cell provides efficient interaction between students, faculty, business enthusiasts with existing
              mentors, entrepreneurs, venture capitalists and many others.</p>
          </div>
        </div>
      </div>
    </div>
  </section>
);

export default Mission;
