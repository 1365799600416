import React, { Component } from "react";
import data from "../data/initiatives";
import Card from "../components/card";

const Event = ({ image, title }) => {
  return (
    <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 column">
      <div className="event">
        <Card image={image} title={title} className={"initiative-card"}/>
      </div>
    </div>
  );
};

export default class extends Component {
  constructor (props) {
    super(props);
  }

  componentDidMount () {
  }

  render () {
    let slides = [];
    for (let i = 0; i < data.length; i = i + 1) {
      slides.push(data[i]);
    }
    return (
      <section id="initiatives" className="section events initiatives fill-bg">

        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="section-heading">
                <h6 className="subtitle text-primary">INITIATIVES</h6>
                <h2 className="display-4">Startup Initiatives</h2>
              </div>
            </div>
          </div>
          <div id="carouselExampleControls" className="carousel slide" data-ride="carousel">
            <div className="carousel-inner">
              {
                slides.map((slide, index) => {
                  return (
                    <div className={"carousel-item " + (index === 0 ? "active" : "")} key={index}>
                      <div className="row">
                        {
                          slide ? <Event {...slide} key={index}/> :
                            <div className={"col-lg-12 col-md-12 col-sm-12 col-xs-12 column"} {...slide}
                                 key={index}/>
                        }
                      </div>
                    </div>
                  );
                })
              }
            </div>
            <a className="carousel-control-prev" href="#carouselExampleControls" role="button" data-slide="prev">
              <span className="carousel-control-prev-icon" aria-hidden="true"></span>
              <span className="sr-only">Previous</span>
            </a>
            <a className="carousel-control-next" href="#carouselExampleControls" role="button" data-slide="next">
              <span className="carousel-control-next-icon" aria-hidden="true"></span>
              <span className="sr-only">Next</span>
            </a>
          </div>

        </div>


      </section>
    );
  }
}

//export default OtherMentors;
